.rhls-trial-widget {
  border: none !important;

  .panel-heading {
    background-color: black !important;
    border-radius: 0;

    .panel-title {
      color: white;
    }
  }

  & > .panel-collapse > .panel-body {
    padding: 15px 0;
  }

  .rhls-trial-widget-expand {
    display: block;
    float: right;

    svg {
      fill: white;
      width: 15px;
    }
  }

  .no-progress-msg {
    text-align: center;
  }
}
