@import '../../../styles/variables';
@import "../../../styles/mixins/mediaqueries";

$filter-width: 320px;
$header-font-size: 1.2em;

@mixin filter-heading-title($color: $gray-dark) {
  color: $color;
  font-size: 1.2em;
  font-weight: 200;
  text-transform: uppercase;
}

.catalog-filters {
  display: inline-block;
  vertical-align: top;
  transition: 0.2s;
  flex: 1;
  max-width: $filter-width;
  margin-right: $form-padding;


  @include respond(md, false) {
    margin-right: 0;
    max-width: none;
  }

  @include respond(lg, false) {
    margin-right: 0;
    max-width: none;
  }

  .filter-group-heading {
    @include filter-heading-title(white);
    background-color: $gray-dark;
    padding: $form-padding;
  }

  .filter-clear {
    background-color: $gray-lighter;
    font-weight: 700;
    text-align: center;
    padding: $form-padding;
    cursor: pointer;
  }

  #filter-panel {
    overflow-x: hidden;
    border: 1px solid $gray-lighter;
    margin-bottom: var(--pf-v5-global--spacer--md);

    .panel {
      margin-top: 0;
      border: none;
      border-radius: 0;

      &:not(:last-child) {
        &:after {
          content: '';
          border-bottom: 1px solid $gray-lighter;
          margin-left: $form-padding;
          display: block;
        }
      }

      .filter-panel-toggle {
        .filter-panel-heading {
          cursor: pointer;
          display: flex;
          width: 100%;
          justify-content: space-between;

          .filter-panel-heading-title {
            @include filter-heading-title;
          }

          &:hover {
            .filter-panel-heading-title {
              color: $gray;
            }
          }

          .filter-panel-heading-icon {
            margin-left: auto;

            svg {
              width: 15px;
            }
          }
        }
      }

      .filter-control {
        margin-top: 0;
        width: 100%;

        label {
          width: 100%;
        }
      }

      .filter-control-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .filter-control-badge {
          margin-left: auto;
          color: $gray-light;
        }
      }

      &.single-option-filter {
        .filter-panel-heading {
          background-color: $color-accent-blue;

          &--dark {
            background-color: black;;
          }
        }

        .form-group {
          margin-bottom: 0;
        }

        .filter-control {
          margin-bottom: 0;

          label {
            @include filter-heading-title(white);
          }
        }

        .filter-control-wrapper {
          .filter-control-badge {
            color: white;
          }
        }

        &:not(:last-child) {
          &:after {
            content: '';
            border-bottom: none;
          }
        }
      }
    }

    .filter-panel-body {
      padding-top: 0;

      .form-group:last-child {
        margin-bottom: 0;
      }
    }
  }
}
